.v2_app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

/* TEXT STYLE */

.v2_text_a {
  color : #374957; /* GRAY */
  font-size: 12px;
}

.v2_text_b {
  color : red;
  font-size: 16px;
}

.v2_text_c {
  color : red;
  font-size: 14px;
}

.v2_text_d {
  color : white;
  font-size: 12px;
}

.v2_text_e {
  color : #374957; /* GRAY */
  font-size: 10px;
}

.v2_text_f {
  color : white;
  font-size: 14px;
}

.v2_text_g {
  color : #374957; /* GRAY */
  font-size: 16px;
}

.v2_text_bold { font-weight: bold; }

.v2_text_strike_through { text-decoration-line: line-through; }

.v2_text_underline { text-decoration-line: underline; }

/* POINTER */

.v2_cursor_pointer { text-decoration: none; cursor: pointer; }

.v2_cursor_pointer:hover {
  opacity: 0.5;
}

.v2_custom_pointer_a { background-color: yellow; text-decoration: none; cursor: pointer; }

.v2_custom_pointer_a:hover { background-color: gray; }

/* CUSTOM VIEW */

#catalog_delete { display: none; }

#catalog_root:hover #catalog_delete { display: flex; }

#wholesaler_item {
  position : relative;
  display : flex;
  flex-direction: column;
  border-radius: 5px;
  width : calc(200px - 2px);
  height : calc(150px - 2px);
  margin : 10px 0 0 10px;
  border : 1px solid red;
  float : left;
}

@media only screen and (max-width: 430px) {

  #wholesaler_item {
    width: calc(100% - 22px);
    margin : 10px 10px 0 10px;
  }

}
