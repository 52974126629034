/* CSS TOKO PINTAR */

#root {
  width: 100%;
  height: 100%;
}

#root pre { margin: 0px; }

.home_root {
  display: inline-flex;
  flex-flow: row;
  height: 100vh;
  width: 100%;
}

.home_side_page {
  display: inline-block;
  overflow-y: auto;
  width: 20%;
  height: 100%;
  background-image: linear-gradient(-40deg, #f2ab1d, #ee3c32);
}

.home_main_page {
  display: inline-flex;
  width: 80%;
  height: 100vh;
  flex-flow: column;
}

.default_text_big_red {
  font-size: 20px;
  color: #ea343c;
}

.default_text_big_white {
  font-size: 20px;
  color: #ffffff;
}

.default_text_big_gray {
  font-size: 20px;
  color: #142030;
}

.default_text_big_gray_b {
  font-size: 28px;
  color: #142030;
}

.default_text_medium_gray {
  font-size: 16px;
  color: #142030;
}

.default_text_normal_white {
  font-size: 12px;
  color: #ffffff;
}

.default_text_normal_white_b {
  font-size: 14px;
  color: #ffffff;
}

.default_text_medium_white {
  font-size: 16px;
  color: #ffffff;
}

.default_text_normal_black {
  font-size: 10pt;
  color: #000000;
}

.default_text_normal_gray {
  font-size: 12px;
  color: #192e39;
}

.default_text_normal_gray_b {
  font-size: 14px;
  color: #192e39;
}

.default_text_normal_gray_c {
  font-size: 8px;
  color: #192e39;
}

.default_text_normal_blue {
  font-size: 12px;
  color: #194E84;
}

.default_text_normal_red {
  font-size: 12px;
  color: #ea343c;
}

.bold_text { font-weight: bold; }

.strike_through_text { text-decoration-line: line-through; }

.normal_right_position { right: 20px; }

.normal_padding_left { padding-left: 15px; }

.normal_padding_left_b { padding-left: 10px; }

.normal_padding_left_c { padding-left: 5px; }

.normal_padding_left_d { padding-left: 8px; }

.normal_padding_right { padding-right: 8px; }

.normal_padding_right_b { padding-right: 10px; }

.normal_padding_bottom { padding-bottom: 20px; }

.normal_padding_bottom_b { padding-bottom: 10px; }

.normal_padding_bottom_c { padding-bottom: 30px; }

.normal_padding_bottom_d { padding-bottom: 8px; }

.normal_padding_top { padding-top: 10px; }

.normal_padding_top_b { padding-top: 8px; }

.updown_border_a {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

.side_padding {
  padding-left: 10px;
  padding-right: 10px;
}

.side_padding_b {
  padding-left: 5px;
  padding-right: 5px;
}

.side_padding_c {
  padding-left: 20px;
  padding-right: 20px;
}

.side_padding_d {
  padding-left: 8px;
  padding-right: 8px;
}

.vertical_margin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.vertical_padding {
  padding-top: 5px;
  padding-bottom: 5px;
}

.vertical_padding_b {
  padding-top: 15px;
  padding-bottom: 15px;
}

.vertical_padding_c {
  padding-top: 10px;
  padding-bottom: 10px;
}

.vertical_padding_d {
  padding-top: 20px;
  padding-bottom: 20px;
}

.whole_padding { padding: 8px; }

.whole_padding_b { padding: 2px; }

.whole_padding_c { padding: 15px; }

.whole_padding_d { padding: 5px; }

.table_root_container {
  flex: 1;
  overflow: auto;
  margin: 0 10px 10px 10px;
}

.circle_background {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.circle_background_b {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #194E84;
}

.circle_background_c {
  width: 26px;
  height: 26px;
  border-radius: 13px;
}

.circle_background_d {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.circle_background_e {
  width: 14px;
  height: 14px;
  border-radius: 7px;
}

.circle_background_f {
  width: 4px;
  height: 4px;
  border-radius: 2px;
}

.circle_background_g {
  width: 80px;
  height: 80px;
  border-radius: 40px;
}

.rectangle_background {
  max-width: 200px;
  height: 32px;
}

.display_flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_tp_container {
  display: flex;
  flex-flow: row;
  justify-content: center;
  height: 60px;
  background-color: #ed2933;
  color: #faa892;
  font-size: 20px;
  align-items: center;
}

.custom_padding {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.custom_padding_b {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.custom_padding_c {
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.custom_padding_d {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 19px;
  padding-right: 19px;
}

.custom_padding_e {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 19px;
  padding-right: 19px;
}

.custom_padding_f {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 25px;
  padding-right: 25px;
}

.custom_padding_g {
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
}

.custom_padding_h {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 8px;
  padding-right: 8px;
}

#time_picker_format .react-time-picker__inputGroup__amPm { display: none; }

#time_picker_format .react-time-picker__inputGroup { padding: 0 5px; }

#time_picker_format .react-time-picker__inputGroup__leadingZero { display: none; }

#time_picker_format .react-time-picker__inputGroup__input--hasLeadingZero {
  margin-left: 0px;
  padding-left: 0px;
}

#time_picker_format .react-time-picker__inputGroup__divider {
  padding: 0 5px;
  font-weight: bold;
}

#time_picker_format .react-time-picker__inputGroup__input {
  width: 15px !important;
  text-align: center;
}

.full_flex { flex: 1; }

.half_flex { flex: 0.5; }

.text_container {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.text_container_sub {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.vertical_padding_c .text_container_sub:hover { background-color: #f5dac9; }

.header_bar_container {
  display: flex;
  flex-flow: row;
  flex-shrink: 0;
  height: 60px;
  background-image: linear-gradient(to right, #ee3c32 , #f2ab1d);
}

.header_half_triangle {
  width: 0;
  height: 0;
  border-top: 60px solid #ed2933;
  border-right: 60px solid transparent;
}

.arrow_right {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #f3823b;
}

.arrow_right_b {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 10px solid #f3823b;
}

.arrow_left {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right:15px solid #f3823b;
}

.arrow_left_b {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right:10px solid #f3823b;
}

.header_half_container_end {
  flex: 0.5;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: flex-end;
  align-items: center;
}

.header_half_container_start {
  flex: 0.5;
  display: flex;
  flex-flow: row;
  padding-right: 20px;
  justify-content: flex-start;
  align-items: center;
}

.header_profile_container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.dropdown_loading {
  display: flex;
  justify-content: center;
}

.loading_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.loading_container_b {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.loading_container_c {
  display: flex;
  justify-content: center;
}

.big_image {
  width: 72px;
  height: 72px;
}

.big_image_b {
  width: 102px;
  height: 102px;
}

.big_image_c {
  width: 130px;
  height: 130px;
}

.big_image_d { max-height: 200px; }

.big_image_e {
  width: 40px;
  height: 40px;
}

.mini_image {
  width: 18px;
  height: 18px;
}

.mini_image_b {
  width: 16px;
  height: 16px;
}

.mini_image_c {
  width: 20px;
  height: 20px;
}

.mini_image_d {
  width: 14px;
  height: 14px;
}

.mini_image_e {
  width: 10px;
  height: 10px;
}

.order_root {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.flex_left {
  flex: 0.2;
  display: flex;
  white-space: nowrap;
}

.flex_right {
  flex: 0.8;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.custom_cursor_a { cursor: pointer; }

.container_image {
  display: inline-flex;
  border: 1px solid #e3e6ea;
  flex-shrink: 0;
  margin-right: 10px;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.container_image:hover {
  background-color: #182837;
  border: 1px solid #182837;
}

.container_image_b {
  display: flex;
  border: 1px solid #e3e6ea;
  margin-left: 10px;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.container_image_b:hover {
  background-color: #182837;
  border: 1px solid #182837;
}

.header_container {
  display: flex;
  flex-flow: row;
  flex-shrink: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
  align-items: flex-end;
}

.search_root_container {
  display: flex;
  flex-flow: row;
}

.root_category .search_root_container { justify-content: flex-end; }

.search_container {
  display: flex;
  position: relative;
  flex-flow: row;
  border: 1px solid #e3e6ea;
  padding-left: 10px;
  border-radius: 5px;
  align-items: center;
  height: 35px;
}

.search_container .circle_background_c:hover { background-color: #182837; }

.search_container .circle_background_c { cursor: pointer; }

.label_search_container {
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.dropdown_display .label_search_container { cursor: pointer; }

.dropdown_display .label_search_container:hover { background-color: #182837; }

.layer_a { z-index: 1; }

.layer_b { z-index: 2; }

.side_margin {
  margin-left: 20px;
  margin-right: 20px;
}

.side_margin_b {
  margin-left: 8px;
  margin-right: 8px;
}

.normal_margin_bottom { margin-bottom: 5px; }

.normal_margin_bottom_b { margin-bottom: 8px; }

.normal_margin_top { margin-top: 8px; }

.normal_margin_top_b { margin-top: 6px; }

.normal_margin_top_c { margin-top: 15px; }

.normal_margin_top_d { margin-top: 4px; }

.normal_margin_top_e { margin-top: 10px; }

.normal_margin_top_f { margin-top: 2px; }

.normal_margin_top_g { margin-top: 25px; }

.normal_margin_top_h { margin-top: 20px; }

.normal_margin_left { margin-left: 10px; }

.normal_margin_left_b { margin-left: 7px; }

.normal_margin_left_c { margin-left: 20px; }

.normal_margin_right { margin-right: 7px; }

.normal_position_right { right: 20px; }

.normal_position_right_b { right: 10px; }

.dropdown_wrapper {
  display: inline-flex;
  flex-flow: column;
  align-items: center;
  position: absolute;
}

.dropdown_display { display: inline-block; }

.dropdown_container {
  border: 1px solid #e3e6ea;
  border-radius: 5px;
  margin-left: 10px;
}

.dropdown_chooser {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 180px;
  height: 35px;
  cursor: pointer;
}

.dropdown_chooser:hover .circle_background_b { background-color: #182837; }

.dropdown_content {
  display: inline-block;
  border: 1px solid #e3e6ea;
  border-radius: 5px;
  min-width: 200px;
  max-height: 230px;
  overflow: auto;
}

.dropdown_content_b {
  display: inline-block;
  border: 1px solid #e3e6ea;
  border-radius: 5px;
  width: 260px;
  max-height: 230px;
  overflow: auto;
}

.dropdown_content_c {
  display: inline-block;
  border: 1px solid #e3e6ea;
  border-radius: 5px;
  width: 260px;
  max-height: 186px;
  overflow: auto;
}

.dropdown_content_d {
  display: inline-block;
  border: 1px solid #e3e6ea;
  border-radius: 5px;
  width: 160px;
  max-height: 230px;
  overflow: auto;
}

.dropdown_content_e {
  display: flex;
  flex-flow: column;
  border: 1px solid #e3e6ea;
  border-radius: 5px;
  width: 260px;
}

.dropdown_content_f {
  display: inline-block;
  width: 260px;
  max-height: 230px;
  overflow: auto;
}

.search_container .dropdown_content { border: 1px solid #e3e6ea; }

.search_container .dropdown_content .side_padding:hover {
  background-color: #ffffff;
}

.dropdown_content_b .side_padding:hover {
  background-color: #e3e6ea;
}

.dropdown_content_b .side_padding {
  cursor: pointer;
}

.dropdown_content_c .side_padding:hover {
  background-color: #e3e6ea;
}

.dropdown_content_c .side_padding {
  cursor: pointer;
}

.dropdown_content_d .side_padding:hover {
  background-color: #e3e6ea;
}

.dropdown_content_d .side_padding {
  cursor: pointer;
}

.dropdown_content_f .side_padding:hover {
  background-color: #e3e6ea;
}

.dropdown_content_f .side_padding {
  cursor: pointer;
}

.dropdown_content .side_padding { cursor: pointer; }

.filter_container .dropdown_content .side_padding:hover { background-color: #e3e6ea; }

.dropdown_filter {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  height: 45px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #e3e6ea;
}

.arrow_container {
  display: flex;
  justify-content: center;
}

.gray_background { background-color: #e3e6ea; }

.gray_background_b { background-color: #fbfbfb; }

.gray_background_c { background-color: #eeeff3; }

.white_background { background-color: #ffffff; }

.orange_background { background-color: #f3823b; }

.blue_background { background-color: #194E84; }

.pink_background { background-color: #ed657b; }

.black_background { background-color: #000000 }

.green_background { background-color: #43987b; }

.green_background_2 { background-color: #00ff00; }

.green_linear_bg { background-image: linear-gradient(-40deg, #434f03, #dad863); }

.red_background { background-color: #ea343c; }

.red_linear_bg { background-image: linear-gradient(-40deg, #ea343c, #f3823b); }

.yellow_background { background-color: #ffff00; }

.text_area_input {
  width: 250px;
  min-height: 95px;
  resize: none;
  border-radius: 5px;
  border: 1px solid #e3e6ea;
  height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
}

.text_area_input_b {
  min-width: 400px;
  min-height: 95px;
  resize: none;
  border-radius: 5px;
  border: 1px solid #e3e6ea;
  height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
}

.text_area_input_c {
  min-width: 400px;
  min-height: 45px;
  resize: none;
  border-bottom: 1px solid #e3e6ea;
  border-right: 0px transparent;
  border-left: 0px transparent;
  border-top: 0px transparent;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
}

.non_transparent_text_area {
  min-width: 400px;
  min-height: 45px;
  resize: none;
  border-bottom: 1px solid #e3e6ea;
  border-right: 0px transparent;
  border-left: 0px transparent;
  border-top: 0px transparent;
  padding: 5px;
  outline: none;
  background-color: #e3e6ea;
  border-radius: 5px;
}

.non_transparent_input {
  width: 220px;
  border-radius: 5px;
  border: 1px solid #e3e6ea;
  height: 35px;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
}

.non_transparent_input_b {
  border: 1px solid #e3e6ea;
  background-color: #e3e6ea;
  border-radius: 5px;
  flex: 1;
  outline: none;
  height: 35px;
  padding-left: 5px;
  padding-right: 5px;
}

.non_transparent_input_c {
  border: 1px solid #e3e6ea;
  border-radius: 5px;
  flex: 1;
  outline: none;
  width: 240px;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
}

.non_transparent_input_inactive {
  width: 220px;
  border-radius: 5px;
  background-color: #e3e6ea;
  border: 1px solid #e3e6ea;
  height: 35px;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
}

.non_transparent_input_inactive_b {
  border-radius: 5px;
  background-color: #e3e6ea;
  border: 1px solid #e3e6ea;
  height: 35px;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
}

.transparent_input {
  border: none;
  border-color: transparent;
  outline: none;
  width: 220px;
}

.transparent_input_b {
  border: none;
  border-color: transparent;
  outline: none;
  width: 50px;
  height: 18px;
  text-align: center;
}

.transparent_input_c {
  border: none;
  border-color: transparent;
  outline: none;
  height: 18px;
  padding-left: 5px;
  padding-right: 5px;
}

.transparent_input_d {
  border: none;
  border-color: transparent;
  outline: none;
  height: 18px;
  text-align: center;
}

.center_content {
  display: flex;
  flex-flow: nowrap;
  justify-content: center;
  align-items: center;
}

.center_content_b {
  justify-content: center;
  align-items: center;
}

.filter_container {
  padding-top: 10px;
  display: flex;
  flex-flow: row;
}

.table_overflow_mask {
  display: block;
  right: 0;
  width: 18px;
  height: calc(100% - 244px);
  position: absolute;
  background-color: #ffffff;
}

.table_root {
  display: block;
}

.scrollable { overflow: auto; }

.table_default_container {
  flex: 1;
  display: inline-block;
  table-layout: fixed;
  border-collapse: collapse;
}

.table_default_container th {
  background-color: #dddddd;
  white-space: nowrap;
  text-align: left;
}

.table_default_container td {
  white-space: nowrap;
  border-bottom: 1px solid #e3e6ea;
}

.table_default_container tr:hover td { background-color: #f7f7f8; }

.table_default_container tr:first-child th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table_default_container tr:last-child th:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.table_default_container .main_column { width: 100%; }

.table_default_container .default_button {
  cursor: pointer;
  min-width: 70px;
  padding: 5px 10px 5px 10px;
}

.table_default_container .default_button_inactive {
  min-width: 70px;
  padding: 5px 10px 5px 10px;
}

.table_default_container .default_button:hover { background-color: #182837; }

.table_container {
  flex: 1;
  display: inline-block;
  table-layout: fixed;
  margin-left: 10px;
  margin-right: 10px;
  overflow: auto;
  border-collapse: collapse;
}

.table_container th {
  background-color: #dddddd;
  white-space: nowrap;
  text-align: left;
}

.table_container td {
  white-space: nowrap;
  border-bottom: 1px solid #e3e6ea;
}

.table_container tr:hover td {
  background-color: #f7f7f8;
}

.table_container tr:first-child th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table_container tr:last-child th:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.table_container .main_column { width: 100%; }

.pagination_root {
  flex-shrink: 0;
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  justify-content: flex-end;
}

.btn_previous {
  height: 30px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn_previous_dead {
  height: 30px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_previous:hover {
  background-color: #194E84;
}

.btn_next {
  height: 30px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn_next_dead {
  height: 30px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_next:hover {
  background-color: #194E84;
}

/* Dashboard CSS */

.dashboard_root {
  display: flex;
  flex: 1;
  flex-flow: row;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.main_dashboard_grid {
  display: flex;
  flex-flow: column;
  padding-left: 10px;
  padding-bottom: 10px;
}

.main_action_container {
  background-color: #194e84;
  display: flex;
  flex-flow: row;
  padding: 10px;
  align-items: center;
  width: 140px;
  height: 60px;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.dashboard_content {
  display: flex;
  flex-flow: column;
  flex: 1;
  margin-left: 10px;
}

.dashboard_header {
  padding: 20px;
}

.wrapper_left_form_dashboard {
  flex: 0.3;
  white-space: nowrap;
  flex-shrink: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
}

.wrapper_left_form_dashboard_b {
  flex: 0.3;
  white-space: nowrap;
  flex-shrink: 0;
  display: flex;
  flex-flow: row;
}

.wrapper_right_form_dashboard {
  flex: 0.7;
  white-space: nowrap;
  flex-shrink: 0;
  display: flex;
  flex-flow: column;
}

.wrapper_right_area_dashboard {
  flex: 0.7;
  flex-shrink: 0;
}

.table_form_dashboard {
  flex: 1;
  display: inline-block;
  table-layout: fixed;
  flex-shrink: 0;
  white-space: normal;
  border-collapse: collapse;
}

.table_form_dashboard tr td:nth-child(2) {
  width: 100%;
}

.wrapper_image_form_dashboard {
  flex: 1;
  white-space: nowrap;
  flex-shrink: 0;
  padding-bottom: 20px;
  height: 200px;
}

.wrapper_dashboard_button {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-flow: row;
}

.wrapper_dashboard_button_b {
  flex: 1;
  display: inline-flex;
  flex-flow: row;
}

.wrapper_dashboard_button_b .red_background:hover { background-color: #182837; }

.wrapper_dashboard_button_b .orange_background:hover { background-color: #182837; }

.wrapper_popup_editor {
  flex: 1;
  display: flex;
  flex-flow: row;
  margin-top: 8px;
}

/* Print CSS */

.print_root {
  display: inline-flex;
  flex-flow: column;
  width: 100%;
}

.receipt_container {
  margin-left: 20px;
  margin-right: 20px;
  display: inline-flex;
  flex-flow: column;
  padding-top: 10px;
  padding-bottom: 10px;
}

.print_header_container {
  border-top: 1px solid #ed2933;
  border-bottom: 1px solid #ed2933;
  white-space: nowrap;
  font-size: 20px;
  color: #faa892
}

.table_print_info_container {
  display: inline-flex;
  border-collapse: collapse;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.table_print_data_container {
  display: inline-block;
  border-collapse: collapse;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.table_print_info_container tbody {
  width: 100%;
}

.table_print_info_container tr {
  border-bottom: 1px solid #e3e6ea;
  height: 40px;
}

.table_print_info_container tr td:nth-child(3) {
  width: 50%;
}

.table_print_info_container tr td:nth-child(6) {
  width: 50%;
}

.table_print_data_container .special_tr_product:nth-child(odd) {
  background-color: #dddddd;
}

.table_print_data_container tr th:nth-child(1) {
  width: 100%;
  text-align: left;
}

.table_print_data_container tr .special_th_data {
  white-space: nowrap;
  text-align: center;
}

.table_print_data_container tr td:nth-child(4) {
  white-space: nowrap;
  text-align: center;
}

.table_print_data_container tr td:nth-child(5) {
  white-space: nowrap;
  text-align: right;
}

.table_print_data_container tr th:nth-child(5) {
  white-space: nowrap;
  text-align: right;
}

.table_print_data_container th {
  border-bottom: 1px solid #e3e6ea;
}

.normal_top_border {
  border-top: 3px solid #e3e6ea;
}

.normal_top_border_b {
  border-top: 1px solid #e3e6ea;
}

.normal_top_border_c {
  border-top: 1px solid #000000;
}

.normal_border_bottom {
  border-bottom: 1px solid #e3e6ea;
}

.normal_border_bottom_b {
  border-bottom: 2px solid #c8cccf;
}

.normal_border_bottom_white {
  border-bottom: 2px solid #ffffff;
}

.address_container {
  display: inline-flex;
  flex-flow: column;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 28px;
  padding-right: 28px;
  border: 1px solid #e3e6ea;
}

.print_status {
  display: flex;
  align-items: center;
}

/* Process Dialog CSS */

.process_dialog_root {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.process_dialog_container {
  display: inline-flex;
  flex-flow: column;
  width: 70%;
  max-height: 90%;
  border-radius: 5px;
  background-color: #ffffff;
}

.process_dialog_container_b {
  display: flex;
  flex-flow: column;
  padding: 20px;
  max-height: 90%;
  overflow: auto;
  border-radius: 5px;
  background-color: #ffffff;
}

.process_dialog_container_c {
  display: inline-flex;
  flex-flow: column;
  max-width: 70%;
  max-height: 90%;
  border-radius: 5px;
  background-color: #ffffff;
}

.process_dialog_page {
  display: inline-flex;
  flex-flow: column;
  height: 100%;
  padding: 10px;
  overflow: auto;
}

.process_dialog_title {
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.table_header_process_container {
  display: inline-flex;
  border-collapse: collapse;
  flex-shrink: 0;
}

.table_data_process_container {
  display: inline-block;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-shrink: 0;
}

.table_data_process_container tr:nth-child(even) {
  background-color: #dddddd;
}

.table_data_process_container tr th:nth-child(1) {
  width: 80%;
  text-align: left;
}

.table_data_process_container tr th:nth-child(2), .table_data_process_container tr td:nth-child(2) {
  width: 10%;
  text-align: center;
}

.table_data_process_container tr th:nth-child(3), .table_data_process_container tr td:nth-child(3) {
  width: 10%;
  text-align: center;
}

.table_data_process_container tr th:nth-child(4) {
  text-align: center;
}

.table_data_process_container tbody tr, .table_data_process_container thead tr {
  height: 40px;
}

.table_data_process_container th {
  background-color: #dddddd;
  white-space: nowrap;
  text-align: left;
}

.table_data_process_container td {
  white-space: nowrap;
  border-bottom: 1px solid #e3e6ea;
}

.table_data_process_container tr:hover td {
  background-color: #f7f7f8;
}

.table_data_process_container tr:first-child th:first-child {
  border-top-left-radius: 5px;
}

.table_data_process_container tr:last-child th:last-child {
  border-top-right-radius: 5px;
}

.react-time-picker__wrapper {
  width: 200px;
}

.react-date-picker__wrapper {
  width: 200px;
  padding-left: 5px;
}

.quantity_edit_wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.button_process_wrapper {
  display: flex;
  flex-flow: row;
  flex: 1;
  flex-shrink: 0;
  padding-left: 8px;
  padding-right: 8px;
}

.button_process_wrapper_b {
  display: flex;
  flex-flow: row;
}

.button_process_wrapper_c {
  display: flex;
  flex-flow: row;
}

.process_button {
  flex: 0.5;
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 18px;
  cursor: pointer;
}

.process_button_b {
  flex: 0.5;
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.process_button_C {
  flex: 0.5;
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.process_button_d {
  display: flex;
  flex: 1;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.process_button_e {
  flex: 1;
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.process_button_f {
  flex: 0.5;
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.process_button_g {
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.process_button_h { width: 50px; }

.process_button_i {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.non_process_button_a {
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.button_process_wrapper .process_button:hover { background-color: #182837; }

.button_process_wrapper_b .process_button_C:hover { background-color: #182837; }

.button_process_wrapper_b .process_button_e:hover { background-color: #182837; }

.button_process_wrapper_c .process_button_b:hover { background-color: #182837; }

.button_process_wrapper_c .process_button_d:hover { background-color: #182837; }

.product_wrapper_dialog {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.tag_flashsale {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  height: 28px;
  flex: 1;
  border-radius: 5px;
}

.pop_up_dialog_container {
  display: inline-flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  max-width: 50%;
  max-height: 50%;
  border-radius: 5px;
  background-color: #ffffff;
  word-break: break-all;
}

.pop_up_dialog_container_b {
  display: flex;
  flex-flow: column;
  max-width: 70%;
  max-height: 50%;
  border-radius: 5px;
  background-color: #ffffff;
  word-break: break-all;
}

.pop_up_dialog_container_c {
  display: flex;
  flex-flow: column;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  width: 240px;
  max-height: 50%;
  border-radius: 5px;
  background-color: #ffffff;
  word-break: break-all;
}

/* Product CSS */

.product_root {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.product_wrapper {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding-left: 10px;
  padding-right: 10px;
}

.search_product_container {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
}

.search_product_wrapper {
  display: flex;
  flex-direction: row;
  border: 1px solid #e3e6ea;
  padding: 5px;
  border-radius: 5px;
}

.search_product_wrapper .circle_background_c:hover { background-color: #182837; }

.search_product_wrapper .circle_background_c { cursor: pointer; }

.product_search_filter {
  display: flex;
  flex-flow: row;
  width: 110px;
  background-color: #194E84;
  padding-top: 5px;
  white-space: nowrap;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 6px;
  border-radius: 5px;
}

.dropdown_display .product_search_filter { cursor: pointer; }

.dropdown_display .product_search_filter:hover { background-color: #182837; }

.search_product_wrapper .dropdown_content .side_padding:hover { background-color: #e3e6ea; }

.product_toolbar_wrapper {
  padding-top: 20px;
  display: inline-flex;
  flex-flow: row;
  flex-shrink: 0;
}

.product_toolbar_left {
  flex: 0.5;
  display: inline-flex;
  flex-flow: row;
}

.product_toolbar_right {
  flex: 0.5;
  display: inline-flex;
  flex-flow: row;
  justify-content: flex-end;
}

.table_product {
  display: inline-block;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.table_product thead { background-color: #dddddd; }

.table_product th {
  white-space: nowrap;
  text-align: left;
}

.table_product .main_column { width: 100%; }

.table_product td {
  white-space: nowrap;
  text-align: left;
}

.table_product tr:first-child th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table_product tr:last-child th:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.table_product tr td:last-child { min-width: 150px; }

.table_product tr:hover td { background-color: #f7f7f8; }

.product_name_wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.product_pagination {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
}

/* PRODUCT DIALOG CSS */

.product_dialog_container {
  display: inline-flex;
  flex-flow: column;
  width: 70%;
  max-height: 90%;
  border-radius: 5px;
  background-color: #ffffff;
  padding-bottom: 20px;
  overflow-y: auto;
}

.wrapper_form_product {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}

.wrapper_left_form_product {
  flex: 0.3;
  white-space: nowrap;
  flex-shrink: 0;
  display: flex;
  flex-flow: row;
}

.wrapper_right_form_product {
  flex: 0.7;
  white-space: nowrap;
  flex-shrink: 0;
  display: flex;
  flex-flow: column;
}

.wrapper_status_product {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdown_mask {
  width: 100%;
  height: 100%;
  top : 0;
  left: 0;
  position: fixed;
  background-color: 'red';
}

.dropdown_container_static {
  width: 150px;
  border-radius: 5px;
  padding-right: 10px;
  position: relative;
}

.dropdown_chooser_static {
  display: flex;
  height: 28px;
  flex-flow: row;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
}

.dropdown_container_category {
  border: 1px solid #e3e6ea;
  border-radius: 5px;
  width: 250px;
  padding-right: 10px;
  position: relative;
}

.dropdown_chooser_category {
  display: flex;
  flex-flow: row;
  align-items: center;
  height: 35px;
  padding-left: 10px;
  cursor: pointer;
}

.dropdown_chooser_category:hover .circle_background_b {
  background-color: #182837;
}

.table_data_price {
  display: inline-block;
  border-collapse: collapse;
}

.table_data_price td {
  white-space: nowrap;
  border-bottom: 1px solid #e3e6ea;
}

.table_data_price tr td:nth-child(1) {
  text-align: center;
  padding-left: 10px;
}

.table_data_price tr td:nth-child(2) {
  text-align: center;
}

.table_data_price tr td:nth-child(3) {
  text-align: center;
}

.table_data_price tr td:nth-child(4) {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

.table_data_price td .blue_background {
  cursor: pointer;
}

.table_data_price td .blue_background:hover {
  background-color: #182837;
}

.wrapper_non_action {
  display: flex;
  flex-flow: row;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.wrapper_product_action {
  display: flex;
  flex-flow: row;
}

.wrapper_product_action .action_button { cursor: pointer; }

.wrapper_product_action .action_button:hover { background-color: #182837; }

.wrapper_product_action .green_background { cursor: pointer; }

.wrapper_product_action .green_background:hover { background-color: #182837; }

.wrapper_product_action .blue_background { cursor: pointer; }

.wrapper_product_action .blue_background:hover { background-color: #182837; }

.wrapper_product_action .red_background { cursor: pointer; }

.wrapper_product_action .red_background:hover { background-color: #182837; }

/* DELIVERY CSS */

.delivery_root {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.table_container_delivery {
  display: inline-block;
  flex: 1;
  table-layout: fixed;
  margin-left: 10px;
  margin-right: 10px;
  overflow: auto;
  border-collapse: collapse;
}

.table_container_delivery th {
  background-color: #dddddd;
  white-space: nowrap;
  text-align: left;
}

.table_container_delivery td {
  white-space: nowrap;
  text-align: left;
  border-bottom: 1px solid #e3e6ea;
}

.table_container_delivery tr:hover td {
  background-color: #f7f7f8;
}

.table_container_delivery tr:first-child th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table_container_delivery tr:last-child th:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.table_container_delivery td .blue_background {
  cursor: pointer;
}

.table_container_delivery .main_column { width: 100%; }

.table_container_delivery td .blue_background:hover {
  background-color: #182837;
}

/* DELIVERY DIALOG CSS */

.delivery_dialog_container {
  padding: 20px;
  display: flex;
  border-radius: 5px;
  flex-flow: column;
  background-color: #ffffff;
}

.delivery_form_container {
  display: flex;
  flex-flow: row;
}

.delivery_form_container #form_label {
  display: flex;
  width: 70px;
  flex-shrink: 0;
  align-items: center;
}

.delivery_form_container #form_input {
  align-items: center;
  display: flex;
  flex-flow: row;
}

#form_input .label_search_container {
  cursor: pointer;
}

#form_input .label_search_container:hover {
  background-color: #182837;
}

/* RETAIL CSS */

.table_container_retail {
  display: inline-block;
  flex: 1;
  table-layout: fixed;
  margin-left: 10px;
  margin-right: 10px;
  overflow: auto;
  border-collapse: collapse;
}

.table_container_retail th {
  background-color: #dddddd;
  white-space: nowrap;
  text-align: left;
}

.table_container_retail td {
  border-bottom: 1px solid #e3e6ea;
  white-space: nowrap;
}

.table_container_retail .main_column { width: 100%; }

.table_container_retail tr:hover td {
  background-color: #f7f7f8;
}

.table_container_retail tr:first-child th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table_container_retail tr:last-child th:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.add_retail_dialog_container {
  padding: 20px;
  display: flex;
  border-radius: 5px;
  flex-flow: column;
  background-color: #ffffff;
}

.default_dialog_container {
  padding: 0 20px 20px 20px;
  display: flex;
  border-radius: 5px;
  flex-flow: column;
  background-color: #ffffff;
}

/* CATEGORY CSS */

.root_category {
  display: flex;
  flex-flow: column;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.tab_container {
  display: flex;
  flex-flow: row;align-items: flex-end;
  height: 50px;
  background-color: #dbdee3;
}

.tab_pane {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 35px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top: 1px solid #d6d9de;
  border-left: 1px solid #d6d9de;
  border-right: 1px solid #d6d9de;
  width: 160px;
  cursor: pointer;
}

.tab_pane:hover {
  background-color: #ffffff;
  border-bottom: 2px solid #ffffff;
}

.first_tab_divider {
  flex-shrink: 0;
  width: 20px;
  height: 2px;
  background: #c8cccf;
}

.vertical_divider {
  height: 100%;
  width: 1px;
  background: #c8cccf;
}

.last_tab_divider {
  flex: 1;
  height: 2px;
  background: #c8cccf;
}

.tab_divider {
  flex-shrink: 0;
  width: 10px;
  height: 2px;
  background: #c8cccf;
}

.table_container_category {
  width: 100%;
  border-collapse: collapse;
}

.table_container_category th {
  background-color: #dddddd;
  white-space: nowrap;
  text-align: left;
}

.table_container_category td {
  text-align: left;
  white-space: nowrap;
  border-bottom: 1px solid #e3e6ea;
}

.table_container_category tr:hover td { background-color: #f7f7f8; }

.table_container_category tr:first-child th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table_container_category tr:last-child th:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.table_container_category .main_column { width: 100%; }

/* ACCESS CODE CSS */

.table_container_acess {
  display: inline-block;
  flex: 1;
  table-layout: fixed;
  margin-left: 10px;
  margin-right: 10px;
  overflow: auto;
  border-collapse: collapse;
}

.table_container_acess th {
  background-color: #f7f7f8;
  white-space: nowrap;
  text-align: left;
}

.table_container_acess td {
  text-align: left;
  border-bottom: 1px solid #e3e6ea;
}

.table_container_acess tr:hover td {
  background-color: #f7f7f8;
}

.table_container_acess tr:first-child th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table_container_acess tr:last-child th:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.table_container_acess tr th:nth-child(1) {
  width: 20%;
}

.table_container_acess tr th:nth-child(2) {
  text-align: center;
  width: 20%;
}

.table_container_acess tr th:nth-child(4) {
  text-align: center;
  width: 20%;
}

.table_container_acess tr th:nth-child(5) {
  width: 40%;
}

.table_container_acess tr td:nth-child(2) {
  text-align: center;
}

.table_container_acess tr td:nth-child(3) {
  text-align: center;
}

.table_container_acess tr td:nth-child(4) {
  text-align: center;
}

/* DETAIL PRODUCT CSS */

.product_detail_footer {
  width: 100%;
  height: 10px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #f3823b;
}

/* BANNER CSS */

.banner_container {
  display: flex;
  flex-flow: column;
  overflow: auto;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
}

.banner_row_wrapper {
  display: flex;
  flex-shrink: 0;
  flex-flow: row;
  height: 200px;
  margin-bottom: 20px;
}

.banner_image_container {
  border: 1px solid #f2ab1d;
  border-radius: 5px;
  flex: 0.5;
  height: 180px;
  padding: 10px;
  position: relative;
}

.banner_action_wrapper {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  top: 0;
  margin-top: 20px;
  width: calc(100% - 30px);
}

.banner_action_wrapper .blue_background {
  cursor: pointer;
}

.banner_action_wrapper .red_background {
  cursor: pointer;
}

.banner_action_wrapper .blue_background:hover {
  background-color: #182837;
}

.banner_action_wrapper .red_background:hover {
  background-color: #182837;
}

.wrapper_dropdown_search {
  position: relative;
  width: 250px;
}

/* UPLOAD CSS */

.upload_root {
  display: flex;
  flex-flow: column;
  border: 1px solid #e3e6ea;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
}

.upload_content {
  display: flex;
  flex-flow: row;
}

.upload_content .on_center {
  align-items: center;
}

.upload_input_wrapper {
  flex: 1;
  padding-right: 20px;
  display: flex;
  flex-flow: column;
}

.upload_button {
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.upload_button:hover {
  background-color: #182837;
}

/* PRODUCTS CATALOG CSS */

.product_catalog_root {
  display: flex;
  flex-flow: column;
}

.catalog_products {
  width: 100%;
  padding-bottom: 10px;
}

.box_product {
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
  border-radius: 5px;
  float: left;
  height: 330px;
  border: 1px solid #e3e6ea;
  margin-top: 10px;
  margin-left: 10px;
}

.box_product_info {
  display: flex;
  flex-flow: column;
  padding: 10px;
  flex: 1;
}

.box_product_image {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.box_product_price {
  display: flex;
  flex-flow: row;
}

.box_product_table {
  display: inline-block;
  border-collapse: collapse;
  width: 100%;
}

.catalog_header_container {
  background-color: #ed2933;
  border-radius: 5px;
  white-space: nowrap;
  text-align: center;
  font-size: 20px;
  color: #faa892;
  margin: 10px;
  cursor: pointer;
}

/* DELIVERY MONITORING */

.root_delivery_monitoring {
  display: flex;
  flex-flow: column;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.table_container_doc_delivery {
  flex: 1;
  display: inline-block;
  table-layout: fixed;
  margin-left: 10px;
  margin-right: 10px;
  overflow: auto;
  border-collapse: collapse;
}

.table_container_doc_delivery th {
  background-color: #dddddd;
  white-space: nowrap;
  text-align: left;
}

.table_container_doc_delivery td {
  white-space: nowrap;
  text-align: left;
  border-bottom: 1px solid #e3e6ea;
}

.table_container_doc_delivery tr:hover td {
  background-color: #f7f7f8;
}

.table_container_doc_delivery tr:first-child th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table_container_doc_delivery tr:last-child th:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.table_container_doc_delivery .main_column { width: 100%; }

/* DIALOG DELIVERY MONITORING */

.selector_action {
  display: flex;
  justify-content: center;
  flex-flow: column;
  padding-top: 33px;
  flex-shrink: 0;
}

.add_all_selector {
  display: flex;
  flex-flow: row;
}

.table_selector_wrapper {
  width: 100%;
  display: flex;
  flex-flow: row;
  margin-top: 20px;
  margin-bottom: 10px;
}

.table_selector_container {
  height: 200px;
  flex: 1;
  overflow: auto;
  flex-shrink: 0;
  border: 1px solid #f3823b;
}

.table_selector {
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
}

.table_selector tr td:nth-child(1) {
  white-space: nowrap;
}

.table_selector tr td:nth-child(3) {
  white-space: nowrap;
  width: 100%;
}

.table_selector th {
  background-color: #f3823b;
  white-space: nowrap;
  text-align: center;
}

/* PRINT DELIVERY */

.tbl_print_delivery_container {
  display: inline-flex;
  border-collapse: collapse;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.tbl_print_delivery_container tr {
  border-bottom: 1px solid #e3e6ea;
  white-space: nowrap;
  height: 40px;
}

.tbl_print_delivery_container tbody {
  width: 100%;
}

.tbl_print_delivery_container tr td:nth-child(3) {
  width: 50%;
}

.tbl_print_delivery_container tr td:nth-child(6) {
  width: 50%;
}

.tbl_print_data_container {
  display: inline-block;
  border-collapse: collapse;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.tbl_print_data_container .special_tr_product:nth-child(odd) {
  background-color: #dddddd;
}

.tbl_print_data_container tr th:nth-child(1) {
  width: 90%;
  text-align: left;
}

.tbl_print_data_container tr th:nth-child(2) {
  text-align: center;
}

.tbl_print_data_container tr th:nth-child(3) {
  text-align: center;
}

.tbl_print_data_container tr th:nth-child(4) {
  text-align: center;
}

.tbl_print_data_container tr th:nth-child(6) {
  white-space: nowrap;
}

.tbl_print_data_container tr th:nth-child(8) {
  width: 10%;
}

.tbl_print_data_container tr td:nth-child(3) {
  white-space: nowrap;
  text-align: center;
}

.tbl_print_data_container tr td:nth-child(4) {
  text-align: center;
  white-space: nowrap;
}

.tbl_print_data_container tr td:nth-child(6) {
  text-align: center;
}

.print_signed_border {
  width: 100%;
  height: 12px;
  border-bottom: 1px dashed #ed2933;
}

#special_algn_a {
  text-align: right;
}

#special_algn_b {
  text-align: left;
}

.special_td_data {
  text-align: right;
}

/* ORDER READY TO DELIVER */

.table_ready_delivery {
  display: inline-block;
  flex: 1;
  table-layout: fixed;
  margin-left: 10px;
  margin-right: 10px;
  overflow: auto;
  border-collapse: collapse;
}

.table_ready_delivery th {
  background-color: #dddddd;
  white-space: nowrap;
  text-align: left;
}

.table_ready_delivery td {
  white-space: nowrap;
  text-align: left;
  border-bottom: 1px solid #e3e6ea;
}

.table_ready_delivery tr:hover td {
  background-color: #f7f7f8;
}

.table_ready_delivery tr:first-child th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table_ready_delivery tr:last-child th:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.table_ready_delivery .main_column { width: 100%; }

/* LOGIN */

.root_login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.main_icon_container {
  width: 100%;
}

.login_form_container {
  border-radius: 5px;
  width: 320px;
  display: flex;
  flex-flow: column;
  padding: 30px 15px 30px 15px;
  background-image: linear-gradient(-40deg, #f2ab1d, #ee3c32);
}

.login_editor_container {
  background: #ffffff;
  display: flex;
  flex-flow: row;
  border-radius: 5px;
  align-items: center;
  height: 30px;
}

/* WHOLESALER */

.wholesaler_container {
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  padding: 20px 35px 35px 35px;
  overflow: auto;
}

.wholesaler_card {
  margin-top: 15px;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  flex-flow: row;
  align-items: center;
}

.wholesaler_info {
  flex: 1;
  padding-right: 20px;
  display: flex;
  flex-flow: column;
}

/* ADVANCE SEARCH */

.title_search_container {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.table_search_container {
  display: inline-flex;
  border-collapse: collapse;
  padding: 10px;
}

.table_search_container tbody {
  width: 100%;
}

.time_range_container {
  height: 37px;
  display: flex;
  flex-flow: row;
}

.time_range_container .react-date-picker__wrapper {
  border: thin solid #f3823b;
}

.wrapper_form_search {
  padding: 18px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}

.wrapper_form_search_b {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}

/* PRINT BY PRODUCT */

.tbl_print_product_container {
  display: inline-block;
  border-collapse: collapse;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.tbl_print_product_container .special_tr_product:nth-child(odd) {
  background-color: #dddddd;
}

.tbl_print_product_container tr th {
  white-space: nowrap;
}

.tbl_print_product_container tr th:nth-child(1) {
  width: 100%;
  text-align: left;
}

.tbl_print_product_container tr th:nth-child(2) {
  text-align: center;
}

.tbl_print_product_container tr th:nth-child(3) {
  text-align: center;
}

.tbl_print_product_container tr td:nth-child(2) {
  text-align: center;
}

.tbl_print_product_container tr td:nth-child(3) {
  text-align: center;
}
.tbl_print_product_container tr td:nth-child(5) {
  text-align: center;
}
.tbl_print_product_container tr td:nth-child(6) {
  text-align: center;
}

/* LOG */

.table_container_log {
  display: inline-block;
  flex: 1;
  table-layout: fixed;
  margin-left: 10px;
  margin-right: 10px;
  overflow: auto;
  border-collapse: collapse;
}

.table_container_log th {
  background-color: #dddddd;
  white-space: nowrap;
  text-align: left;
}

.table_container_log td {
  border-bottom: 1px solid #e3e6ea;
  white-space: nowrap;
}

.table_container_log tr:hover td {
  background-color: #f7f7f8;
}

.table_container_log tr:first-child th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table_container_log tr:last-child th:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.table_container_log .main_column { width: 100%; }

/* PRINT MINI ORDER */

.print_mini_root {
  background: #d3d3d3;
  padding: 0;
  float: none;
}

.print_action {
  width: 600px;
  display: flex;
  flex-flow: column;
  margin: 5mm 0 0 5mm;
  padding: 10px;
  border: 2px solid orange;
}

/* ADMIN DASHBOARD */

.admin_root {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.admin_wrapper {
  padding: 0px 20px 20px 0px;
  overflow-y: auto;
}

.wrapper_admin_act {
  cursor: pointer;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  float: left;
  width: 170px;
  height: 170px;
  margin-left: 20px;
  margin-top: 20px;
  padding: 10px;
  background-image : linear-gradient(40deg, #ee3c32 , #f2ab1d);
  border-radius: 10px;
}

.wrapper_admin_act:hover { background-image : linear-gradient(-40deg, #ee3c32 , #f2ab1d); }

/* ANALYZE */

.root_analyze {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.analyze_search_container {
  display: flex;
  flex-flow: column;
  margin: 20px 0 0 0;
  padding: 20px;
  border: 2px solid orange;
}

.wrapper_form_analyze {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
}

/* MEDIA QUERY */

@media (max-width: 700px) {
  .header_half_container_start {
    visibility: collapse;
    padding: 0;
    flex: 0;
    width: 0px;
  }
  .header_half_container_end {
    flex: 1;
  }
  .home_tp_container p {
    visibility: collapse;
    width: 0px;
  }
}

@media (min-width: 701px) {
  .home_tp_container img {
    visibility: collapse;
    width: 0px;
    height: 0px;
  }
}

@media (min-width: 0px) and (max-width: 350px) {
  .box_product {
    width: 350px;
  }
}

@media (min-width: 350px) and (max-width: 700px) {
  .box_product {
    width: 350px;
  }
}

@media (min-width: 700px) and (max-width: 1050px) {
  .box_product {
    width: 50%;
  }
}

@media (min-width: 1050px) {
  .box_product {
    width: 400px;
  }
}

#header_navigation { display: flex; }

#catalog_navigation { display : flex; }

@media print {

  html, body {
    color: #000;
    background-color: #FFFFFF;
    font-family: 'Helvetica';
  }

  .print_root {
    width: auto;
    margin: auto;
    padding: auto;
    float: none;
  }

  #catalog_navigation { display: none; }
  #header_navigation { display: none; }

  .print_mini_container .hide { display: none; }

  @page {
    height: auto;
    margin: 0%;
  }
}
